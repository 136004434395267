<template>
  <v-form ref="formIbpt" lazy-validation>
    <v-card>
      <v-container grid-list-md text-xs-left>
        <v-layout row wrap>
          <v-flex xs12 sm4>
            <v-text-field label="NCM" v-model="item.codigo" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Ex" v-model="item.ex" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Tipo" v-model="item.tipo" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm12>
            <v-text-field label="Descrição" v-model="item.descricao" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Impostos Federais" v-model="item.nacionalfederal" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Impostos de Importação" v-model="item.importadosfederal" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Estado" v-model="item.estado" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Impostos Estaduais" v-model="item.estadual" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Impostos Municipais" v-model="item.municipal" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Chave" v-model="item.chave" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Início da Vigência" v-model="item.vigenciainicio" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Fim da Vigência" v-model="item.vigenciafim" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm4>
            <v-text-field label="Versão" v-model="item.versao" readonly></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({}),

  computed: {
    ...mapState('ibpt', {
      item: 'itemAtual',
      carregando: 'carregando',
    }),
  },

  methods: {},
};
</script>

<style scoped>
.container.grid-list-md .layout .flex {
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}
</style>
